<template>
  <div class="card-calender">
    <div class="top-card" v-if='msgShow'>
      <img src="@/assets/icon/icon_e7fbmzzmou9/rl.jpg" style="width:20px;height:auto" alt="" />
      <div class="anime-title" ref="animeTitle">
        <!-- <span ref="leftMsg" class='left-msg'>{{firstData.lastMsg&&firstData.lastMsg.title}}</span> -->
        <span @mouseover="titleMove" @mouseleave="outMove" ref="rightMsg" class="right-msg">{{
          firstData.lastMsg && firstData.lastMsg.title
        }}</span>
      </div>
    </div>
    <div class=" bottom-card">
      <MobileCalendar
        ref="MobileCalendar"
        :mobileLoading="mobileLoading"
        @getAllData='getAllData'
        :mobileTasks="mobileTasks"
        :mobileEvents="mobileEvents"
        @clickCurrentDaySearch="clickCurrentDaySearch"
        :schedule="schedule"
        :isMyTask="true"
        @clickCurrentDay="clickCurrentDay"
      />
    </div>
  </div>
</template>

<script>
import MobileCalendar from './MobileCalendar'
import { getAction } from '@/api/manage'
const stamptime = time => {
  var date = new Date(time)
  var Y = date.getFullYear() + ''
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + ''
  var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ''
  return Y + M + D
}
export default {
  components: {
    MobileCalendar
  },
  props: {
    firstData: {
      type: Object,
      default: () => ({})
    },
    userInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      titleTimer: null,
      msgShow:true,
      width: 0,
      mobileLoading: false,
      mobileTasks: [],
      schedule: [],
      mobileEvents: [],
      titleWidth: 0
    }
  },
  mounted() {},
  methods: {
    getAllData(){
      this.$emit('getAllData')
    },
    insertStr(source, start, newStr) {
      return source.slice(0, start) + newStr + source.slice(start)
    },
    async clickCurrentDay(info) {
      this.$nextTick(() => {
        this.$refs.MobileCalendar.changeFirstAndLastDay(info.days[0].data, info.days[info.days.length - 1].data)
      })
      if (!info.data) return
      let endDate = new Date(info.data)
      endDate = endDate.setDate(endDate.getDate() + 1)
      endDate = this.insertStr(this.insertStr(stamptime(new Date(endDate)), 4, '-'), 7, '-')
      /* let queryParam = { userIds: this.userInfo.id, startDate: info.data, endDate } */
      let queryParam = { userIds: this.userInfo.id, startDate: info.days[0].data, endDate:info.days[info.days.length - 1].data }
      this.mobileLoading = true
      if (info.viewType == 2) {
        getAction('/tasks/get_custom_event', {
          startDate: info.data,
          endDate: info.data
        }).then(res => {
          this.mobileEvents = res.data
          this.mobileLoading = false
        })
        return
      }
      const res = await getAction('/tasks/my?current=1&size=-1', queryParam)
/*       getAction('/workSchedule/userSchedule', {
        userId: this.userInfo.id,
        startDate: info.days[0].data,
        endDate: info.days[info.days.length - 1].data
      }).then(rest => {
        if (rest.code == 200) {
          this.schedule = []
          for (let key in rest.data.schedule) {
            let item = {
              key: key,
              data: rest.data.schedule[key] || 0,
              isEdit: false,
              count: this.userInfo.workdayAvailableCount - rest.data.schedule[key]
            }
            this.schedule.push(item)
          }
        }
      }) */
      try {
        this.mobileTasks = res.data.records
        console.log(this.mobileTasks,123)
        this.mobileLoading = false
      } catch (err) {
        this.mobileLoading = false
      }
    },
    async clickCurrentDaySearch(info) {
      let queryParam = {
        userIds: this.userInfo.id,
        startDate: info.days[0].data,
        endDate: info.days[info.days.length - 1].data
      }
      this.mobileLoading = true
      const res = await getAction('/tasks/my?current=1&size=-1', queryParam)
      try {
        this.mobileTasks = res.data.records
        this.mobileLoading = false
      } catch (err) {
        this.mobileLoading = false
      }
    },
    changeWidth() {
      this.width = this.$refs.rightMsg.clientWidth
      this.titleWidth = this.$refs.animeTitle.clientWidth
    },
    getWidth() {
      this.$nextTick(() => {
        this.width = this.$refs.rightMsg.clientWidth
        this.titleWidth = this.$refs.animeTitle.clientWidth
        if (this.$refs.rightMsg) {
          this.$refs.rightMsg.style.width = this.width - 0 + 20 + 'px'
        }
      })
      this.titleTimer = setInterval(() => {
        if (this.$refs.rightMsg) {
          if (this.$refs.rightMsg.style.right.split('px')[0] < this.titleWidth) {
            this.$refs.rightMsg.style.right = this.$refs.rightMsg.style.right.split('px')[0] - 0 + 1 + 'px'
          } else {
            this.$refs.rightMsg.style.right = '-' + this.width + 'px'
          }
        }
      }, 20)
    },
    titleMove() {
      clearInterval(this.titleTimer)
    },
    outMove() {
      this.titleTimer = setInterval(() => {
        if (this.$refs.rightMsg) {
          if (this.$refs.rightMsg.style.right.split('px')[0] < this.titleWidth) {
            this.$refs.rightMsg.style.right = this.$refs.rightMsg.style.right.split('px')[0] - 0 + 1 + 'px'
          } else {
            this.$refs.rightMsg.style.right = '-' + this.width + 'px'
          }
        }
      }, 20)
    }
  },
  beforeDestroy() {
    clearInterval(this.titleTimer)
  }
}
</script>

<style lang="less" scoped>
@import '~@assets/less/homeStyle.less';
.top-card {
  display: flex;
  .anime-title {
    width: 95%;
    margin-left: 8px;
    overflow: hidden;
    transition: all 3s;
    position: relative;
    height: 20px;
    .left-msg {
      position: absolute;
      left: -200px;
      top: 0;
    }
    .right-msg {
      position: absolute;
      right: 0;
      top: 0;
      cursor: default;
    }
  }
}
</style>
